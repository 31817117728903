.optionsRenderer {
  display: flex;
  flex-direction: row;
}

.optionsRendererEditButton {
  width: 100% !important;
  margin-right: 2%;
}

.optionsRendererAcceptButton {
  width: 100% !important;
  margin-right: 2%;
}

.optionsRendererDeleteButton {
  width: 100% !important;
}
