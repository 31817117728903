.language__container--small {
  width: 280px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 50px auto;
}

.language__container--large {
  width: 850px;
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(3, calc(100% / 3));
  align-items: center;
  margin: 50px auto;
  justify-content: space-evenly;
}

.language__button {
  width: 280px;
  height: 80px;
  margin-top: 5px;
  font-size: 35px !important;
  color: #5f6b7c !important;
  border-bottom: 2px solid transparent !important;
}

.bp4-button.bp4-minimal.bp4-active, .bp4-button.bp4-minimal.bp4-active:hover {
  background-color: transparent !important;
  border-bottom: 2px solid #F3991D !important;
}

.bp4-button.bp4-minimal:hover {
  background-color: transparent !important;
  border-bottom: 2px solid lightgrey !important;
}
