.stammdatenInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 4% 2% 2% 4%;
}

.stammdatenInput {
  flex: 28;
  flex-wrap: wrap;
  margin-right: 8px;
  margin-left: 6px;
}

.stammdatenInputAndErrorContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
}

.stammdatenEditButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
  margin: 5px;
  padding-top: 10px;
  flex: 19;
  padding-right: 4%;
}

.stammdatenDialogButton {
  height: fit-content;
}
