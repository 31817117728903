.navbar {
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: row;
  min-height: 35px;
}

.navbar__logo {
  width: 20%;
  display: flex;
  align-items: center;
  padding: 1% 1% 0 1%;
}

.navbar__control-buttons {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navbar__control-button {
  width: 15%;
  color: #5f6b7c !important;
  border-bottom: 2px solid transparent !important;
}

.navbar__config-buttons {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navbar__img {
  vertical-align: inherit;
  margin-right: 5px;
}

.navbar__language-select {
  min-width: 90px;
  width: 30% !important;
}

.navbar__language-select-button {
  color: #5f6b7c !important;
  border-bottom: 2px solid transparent !important;
}

.navbar__logout-button {
  width: 43%;
  color: #5f6b7c !important;
  border-bottom: 2px solid transparent !important;
}

.navbar .bp4-button.bp4-minimal.bp4-active, .navbar .bp4-button.bp4-minimal.bp4-active:hover {
  background-color: transparent !important;
  border-bottom: 2px solid #F3991D !important;
}

.navbar .bp4-button.bp4-minimal:hover {
  background-color: transparent !important;
  border-bottom: 2px solid lightgrey !important;
}
