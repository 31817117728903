.historieDialog {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.toolbarContainer {
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 0.3em;
  align-items: center;
  justify-content: space-between;
}

.searchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding-right: 4px;
  border: none
}

.exportHistorisierteFahrzeugeButton {
  display: flex;
  flex-direction: row;
  margin-left: 3px;
  float: left;
}
