.zusatzattributeElement {
  display: flex;
  width: 100%;
  padding: 10px;
  font-weight: bold;
}

.zusatzattributeRowElement {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 98%;
  flex-wrap: wrap;
}

.zusatzattributeColumnElement {
  flex-direction: column;
  justify-content: space-around;
  flex: 0 0 98%;
  flex-wrap: wrap;
}

.zusatzattributeEditorAndErrorContainer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.zusatzattributeHeader {
  margin-bottom: 0 !important;
}

.zusatzattributeCheckboxComponent {
  display: flex;
  flex-direction: row;
}

.zusatzattributeCheckboxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: 94%;
}

.zusatzattributeCheckboxLabel {
  font-weight: bold;
  margin: 0% !important;
}

.zusatzattributeCheckbox {
  align-items: center;
  justify-content: center;
  display: flex !important;
  flex-direction: column;
}

.inputComponent {
  display: flex;
  flex-direction: column;
}

.inputComponentLabel {
  font-weight: bold;
  margin-bottom: 4px !important;
}

.inputAndErrorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input {
  flex-basis: 94% !important;
}

.zusatzattributeTimePickerComponent {
  display: flex;
  flex-direction: row;
}

.zusatzattributeTimePickerComponentLabelAndInputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: 94%;
}

.zusatzattributeTimePickerComponentLabel {
  font-weight: bold;
}

.zusatzattributeTimePicker {
  align-items: center;
  justify-content: center;
  display: flex !important;
  flex-direction: column;
}

.numberInputComponent {
  display: flex;
  flex-direction: column;
}

.numberInputComponentLabel {
  font-weight: bold;
  margin-bottom: 4px !important;
}

.numberInputAndErrorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.numberInput {
  flex-basis: 94% !important;
}
