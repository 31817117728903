.registrierungElement {
  display: flex;
  width: 100%;
  padding: 10px;
  font-weight: bold;
}

.registrierungRowElement {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 98%;
  flex-wrap: wrap;
}

.registrierungColumnElement {
  flex-direction: column;
  justify-content: space-around;
  flex: 0 0 98%;
  flex-wrap: wrap;
}

.inputField {
  display: flex;
  flex-direction: column;
}

.inputFieldLabel {
  font-weight: bold;
  margin-bottom: 4px !important;
}

.input {
  flex-basis: 94%;
}

.inputAndErrorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkboxComponent {
  display: flex;
  flex-direction: row;
}

.checkboxComponentLabel {
  font-weight: bold;
  margin-bottom: 4px !important;
}

.checkboxComponentLabelAndInputWrapper {
  display: flex;
  flex-direction: row;
  flex: 0 0 97%;
  justify-content: space-between;
}

.lieferlandDropdown {
  display: flex;
  flex-direction: column;
}

.lieferlandDropdownLabel {
  font-weight: bold;
  margin-bottom: 4px !important;
}

.dropdown {
  flex-basis: 94% !important;
}

.lieferlandDropdownAndErrorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.suggestOption {
  display: flex;
  flex-direction: column;
}

.suggestOptionLabel {
  font-weight: bold;
  margin-bottom: 4px !important;
}

.suggest {
  flex-basis: 94%;
}

.suggestAndErrorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dateTimePicker {
  display: flex;
  flex-direction: row;
}

.dateTimePickerLabelAndInputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: 94%;
}

.dateTimePickerLabel {
  font-weight: bold;
  margin-top: 15px !important;
}

.dateInput {
  align-items: center;
  justify-content: center;
  display: flex !important;
  flex-direction: column;
  cursor: pointer;
}
