.toolbarContainer {
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 0.3em;
  align-items: center;
  justify-content: left;
}

.optionsRenderer {
  display: flex;
  flex-direction: row;
}

.closeDialogButton {
  font-size: large;
  float: right;
  margin-top: 1%;
}

.importSpeditionButton {
  display: flex;
  flex-direction: row;
  margin-left: 3px;
}
