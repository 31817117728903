.toolbarContainer {
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 0.3em;
  align-items: center;
  justify-content: space-between;
}

.addTorDialog {
  max-width: 30%;
  min-width: 210px;
}

.addTorContainer {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  align-items: center;
  margin-left: 3px;
}

.editTorDialog {
  max-width: 30%;
  min-width: 210px;
}

.editTorContainer {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  align-items: center;
  margin-left: 3px;
}

.deleteTorDialog {
  max-width: 30%;
  min-width: 210px;
  height: 135px;
}

.closeDialogButton {
  font-size: large;
  float: right;
  margin-top: 1%;
}
