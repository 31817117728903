.toolbarContainer {
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 0.3em;
  align-items: center;
  justify-content: space-between;
}

.optionsRenderer {
  display: flex;
  flex-direction: row;
}

.optionsRendererAcceptButton {
  min-width: 32% !important;
  margin-right: 2%;
}

.optionsRendererEditButton {
  min-width: 32% !important;
  margin-right: 2%;
}

.optionsRendererRefuseButton {
  min-width: 32% !important;
}

.deleteSpeditionDialog {
  max-width: 30%;
  min-width: 210px;
  height: 135px;
}

.closeDialogButton {
  font-size: large;
  float: right;
  margin-top: 1%;
}

.exportUnbekannteSpeditionButton {
  display: flex;
  flex-direction: row;
  margin-left: 3px;
  float: left;
}
