.dashboardDialog {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.dashboard {
  width: 100%;
  height: 100%;
  --ag-value-change-value-highlight-background-color: rgb(55 91 142 / 50%);
}

.toolbarContainer {
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 0.3em;
  align-items: center;
  justify-content: space-between;
}

.toolbarContainer__headline {
  color: #707171;
  display: flex;
  align-items: center;
  padding: 1%;
}

.searchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding-right: 4px;
  border: none
}
