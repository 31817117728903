.terminalIsClosedContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.terminalIsClosed {
  width: 100%;
  text-align: center;
}

.languageButtonGroupContainer {
  float: center;
}

.languageButton {
  height: 40px;
  width: 110px;
  margin: 20px auto;
  outline: none !important;
  color: #5f6b7c !important;
  border-bottom: 2px solid transparent !important;
}

.bp4-button.bp4-minimal.bp4-active, .bp4-button.bp4-minimal.bp4-active:hover {
  background-color: transparent !important;
  border-bottom: 2px solid #F3991D !important;
}

.bp4-button.bp4-minimal:hover {
  background-color: transparent !important;
  border-bottom: 2px solid lightgrey !important;
}
