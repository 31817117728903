.stammdaten-dialog {
  padding: 1em 1em 0.5em 1em;
}

.stammdaten-dialog__button-container {
  display: flex;
  justify-content: flex-end;
}

.stammdaten-dialog__submit-button {
  margin-left: 1em;
}
