body {
  overflow: auto;
}

.dialogContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.dialogContainer__innerRegistrierungDialog {
  padding: 30px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

@media (max-width: 1000px) {
  .dialogContainer__registrierungDialog {
    width: 100%;
  }
}

@media (min-width: 1001px) {
  .dialogContainer__registrierungDialog {
    width: 60%;
  }
}

.dialogContainer__registrierungDialog {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.registrierungDialog__backButton {
  width: 50px;
  height: 50px;
  margin-left: 19px;
  margin-top: 2px;
  border-bottom: 2px solid transparent !important;
}

.registrierungDialog__headline {
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: space-between;
  color: #5f6b7c;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
}

.registrierungDialog__headlineElement {
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: flex-end;
}

.registrierungDialog__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registrierungDialog__buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.registrierungDialog__legendenElement {
  width: 15%;
}
