.dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.dialog__sidebarComponent {
  width: 100%;
  height: 100%;
}

.dialog__navbarComponent {
  width: 100%;
  height: 100%;
  padding: 1%;
}
