.allgemeineEinstellungenDialog {
  min-height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allgemeineEinstellungenDialog__cardLabel {
  width: 50%;
  padding-bottom: 2px;
}

.allgemeineEinstellungenDialog__card {
  width: 66%;
  margin: 1px 1% 1% 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
