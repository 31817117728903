.formgroup {
  font-weight: bold;
  margin: 0 !important;
}

.timepicker-formgroup {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox-formgroup {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
