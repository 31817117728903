.stammdaten-sidebar {
  width: 15%;
  display: flex;
  flex-direction: column;
  padding-right: 1%;
}

.stammdaten-sidebar__headline {
  width: 100%;
  height: 2.5em;
  display: flex;
  border-radius: 3px;
  color: #F3991D;
  align-items: center;
  font-weight: bold;
  font-size: 1.2em;
}

.stammdaten-sidebar__headline-element {
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.stammdaten-sidebar__buttons {
  display: flex;
  flex-direction: column;
}

.stammdaten-sidebar__button {
  justify-content: left !important;
  margin-top: 1%;
  width: 100%;
  color: #5f6b7c !important;
  border-bottom: 2px solid transparent !important;
}

.stammdaten-sidebar .bp4-button.bp4-minimal.bp4-active, .stammdaten-sidebar .bp4-button.bp4-minimal.bp4-active:hover {
  background-color: transparent !important;
  border-bottom: 2px solid #F3991D !important;
}

.stammdaten-sidebar .bp4-button.bp4-minimal:hover {
  background-color: transparent !important;
  border-bottom: 2px solid lightgrey !important;
}
