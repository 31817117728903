.confirmation-dialog {
  padding: 1em 1em 0.5em 1em;
}

.confirmation-dialog__text {
  max-width: 80em;
  word-wrap: break-word;
}

.confirmation-dialog__error-message {
  min-height: 18px;
  color: red;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.confirmation-dialog__button-container {
  display: flex;
  justify-content: flex-end;
}

.confirmation-dialog__button {
  min-width: 5em !important;
}

.confirmation-dialog__submit-button {
  margin-left: 1em;
}
