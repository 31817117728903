.searchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #444;
  border-radius: 4px;
  padding-right: 4px;
  border: none;
}

.searchbarInput {
  width: 100%;
  height: 1.5rem;
}
